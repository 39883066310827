import {toFactory} from 'react-ioc';
import {ApolloClient} from '@apollo/client';
import { Store } from 'store/Store';

import ApolloClientFactory from './ApolloClientFactory';

const ApolloClientDI = (): any => [
  ApolloClient,
  toFactory(
    [Store],
    (store) => ApolloClientFactory(store),
  ),
];

export default ApolloClientDI;
