/* eslint-disable */
import {initReactI18next} from 'react-i18next';
import i18n from 'i18next';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './locales/en/translation.json';
import it from './locales/it/translation.json';
import el from './locales/el/translation.json';

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        supportedLngs: ['en', 'it', 'el'],
        detection: {
            order: ['localStorage', 'navigator', 'htmlTag'],
            lookupLocalStorage: 'language',
        },
        // lng: 'it', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // // if you're using a language detector, do not define the lng option
        fallbackLng: 'en',
        debug: false,
        interpolation: {
            escapeValue: false // react already safes from xss
        },
    });

i18next.addResourceBundle('it', 'translation', it);
i18next.addResourceBundle('en', 'translation', en);
i18next.addResourceBundle('el', 'translation', el);

i18next.on('languageChanged', (lng) => {
    localStorage.setItem('language', lng);
});

export default i18n;