import {useEffect} from 'react';
import ReactGA from 'react-ga4';
import type {Location} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import ym from 'react-yandex-metrika';

export const Metrika = () => {

    const location:Location = useLocation();

    useEffect(() => {
        ym('hit', location.pathname);
        ReactGA.send({ hitType: 'pageview', page:  location.pathname, title: 'Moow' });
    }, [location])

    return <></>
} 