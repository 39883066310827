import {gql} from '@apollo/client';

export const QUERY_SUPPORT = gql`
    query support($locale: I18NLocaleCode) {
        support(locale: $locale) {
            data {
                attributes {
                    Title
                    TitleOne
                    BlockOne {
                        id
                        Question
                        Answer
                    }
                    TitleTwo
                    BlockTwo {
                        id
                        Question
                        Answer
                    }
                    TitleThree
                    BlockThree {
                        id
                        Question
                        Answer
                    }
                    TitleFour
                    BlockFour {
                        id
                        Question
                        Answer
                    }
                }
            }
            
        }
    }
`;