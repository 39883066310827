import React from 'react';
import cn from 'classnames'
import _ from 'lodash';

import {Fare, IFare} from './Fare'

import cls from './Fares.module.scss'

export interface IFares {
    items: IFare[]
    className?: string
}

export const Fares = ({items, className}:IFares) => (
        <div className={cn([cls.fares, className])}>
            {_.map(items, (item, index) => (
                <Fare key={index} title={item.title} cards={item.cards}/>
            ))}
        </div>
    )