import React from 'react';
import cn from 'classnames'

import cls from './Icon.module.scss'

interface IIcon {
    src?: string
    width?: number
    height?: number
    className?: string
}

export const Icon = ({src, className, width, height}:IIcon) => (
    <div className={cn([cls.icon, className])} style={{
        ...width && {width: width},
        ...height && {height: height},
        ...src && {backgroundImage: `url(${src})`},
    }}/>
)