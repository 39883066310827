import React, {useEffect,useState} from 'react';
import Sheet from 'react-modal-sheet';
import {Modal} from 'antd';

import cls from './AdaptiveModal.module.scss';

interface IAdaptiveModalProps {
    children?: React.ReactNode;
    // title?: string | null;
    open: boolean;
    onClose: () => void;
}

export const AdaptiveModal = (props: IAdaptiveModalProps) => {

    const {open, children, onClose} = props

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);


    const modalElement =
        <Modal open={open} onCancel={onClose} footer={null}>
            {children}
        </Modal>

    const sheetElement =
            <Sheet isOpen={open} onClose={onClose} tweenConfig={{ease: 'easeInOut', duration: 0.3}}
                   detent={'content-height'} className={cls.sheet}
            >
                <Sheet.Container>
                    <Sheet.Header/>
                    <Sheet.Content>
                        <div className={cls.sheetWrapper}>
                            {children}
                        </div>
                    </Sheet.Content>
                </Sheet.Container>
                {/*<Sheet.Backdrop/>*/}
            </Sheet>

    if (windowSize[0] > 500) {
        return modalElement
    } else {
        return sheetElement
    }
}