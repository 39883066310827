import React from 'react';
import cn from 'classnames'

import cls from './FareHighlights.module.scss'

export interface IFareHighlight {
    title: string
    active: boolean
    className?: string
}

export const FareHighlight = ({title, active, className}:IFareHighlight) => (
    <div className={cn([cls.card, active && cls.active,className])}>
        {title}
    </div>
)