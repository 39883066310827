import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
// @ts-ignore
import TopLoader from 'react-top-loader';
import {useQuery} from '@apollo/client';
import {Block} from 'blocks/Block/Block';
import {Blocks} from 'blocks/Blocks/Blocks';
import _ from 'lodash';

import {SmallCover} from '../../blocks/SmallCover/SmallCover';
import {Title} from '../../blocks/Title/Title';
import {scooter, scooterVariables} from '../../graphql/__generated__/scooter';
import {QUERY_SCOOTER} from '../../graphql/scooter';

import image from './img/img_scooter.png'
import {Dot} from './Dot';

import cls from './Vehicles.module.scss'

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}


export const Vehicles = () => {

    const {i18n} = useTranslation()
    const [loading, setLoading] = useState<boolean>(true)

    const [active, setActive] = useState<string|null>(null)

    const scooter = useQuery<scooter, scooterVariables>(QUERY_SCOOTER, {
        variables: {
            locale: i18n.language
        },
        onCompleted: () => {
            setLoading(false)
        }
    })

    const [width, setWidth] = useState(getWindowDimensions().width);
    useEffect(() => {
        function handleResize() {
            setWidth(getWindowDimensions().width);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const imageWidth = width > 600 ? 560 : width - 40;
    const imageHeight = Math.round(imageWidth * 1.14)

    const data = [
        {
            key: 'holder',
            x: Math.round(imageWidth * 0.55),
            y: Math.round(imageWidth * 0.07),
            description: scooter.data?.scooter?.data?.attributes?.ScooterHolder!,
        },
        {
            key: 'weight',
            x: Math.round(imageWidth * 0.64),
            y: Math.round(imageWidth * 0.4),
            description: scooter.data?.scooter?.data?.attributes?.ScooterWeight!,
        },
        {
            key: 'light',
            x: Math.round(imageWidth * 0.71),
            y: Math.round(imageWidth * 0.7),
            description: scooter.data?.scooter?.data?.attributes?.ScooterLight!,
        },
        {
            key: 'suspension',
            x: Math.round(imageWidth * 0.75),
            y: Math.round(imageWidth * 0.83),
            description: scooter.data?.scooter?.data?.attributes?.ScooterSuspension!,
        },
        {
            key: 'tire',
            x: Math.round(imageWidth * 0.82),
            y: Math.round(imageWidth),
            description: scooter.data?.scooter?.data?.attributes?.ScooterTire!,
        },
        {
            key: 'software',
            x: Math.round(imageWidth * 0.32),
            y: Math.round(imageWidth * 0.8),
            description: scooter.data?.scooter?.data?.attributes?.ScooterSoftware!,
        },
        {
            key: 'power',
            x: Math.round(imageWidth * 0.18),
            y: Math.round(imageWidth * 0.5),
            description: scooter.data?.scooter?.data?.attributes?.ScooterPower!,
        },
    ]

    return (
        <>

            <TopLoader show={loading} color="#FF40B4"/>

            <SmallCover/>

            <Blocks>

                <Block>
                    <Title className={'col6'}>{scooter.data?.scooter?.data?.attributes?.Title}</Title>
                </Block>

                <Block>

                    <div className={cls.wrapper}>
                        <img src={image} alt={'MOOW Scooter'} width={imageWidth} height={imageHeight}/>

                        {_.map(data, item => (<Dot
                                key={item.key}
                                active={active === item.key}
                                disabled={active !== null && active !== item.key}
                                x={item.x}
                                y={item.y}
                                description={item.description}
                                onClick={() => {setActive(active === item.key ? null : item.key)}}
                            />))}

                    </div>

                </Block>

            </Blocks>

        </>
    )
}