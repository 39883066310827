import React from 'react';
import cn from 'classnames';

import img_wave from './wave.svg'

import cls from './Cover.module.scss'

interface ICover {
    title?: string
    suptitle?: string
    subtitle?: string
    bg?: string
    footer?: React.ReactNode
    image?: string
    imageMaxHeight?: number
    imagePosition?: 'bottom' | 'center'
}



export const Cover = ({title, suptitle, subtitle, image, footer, bg, imageMaxHeight, imagePosition = 'center'}: ICover) => (
    <div className={cls.cover} style={{backgroundImage: `url(${bg})`}}>
        <div className={cls.content}>
            {suptitle &&
            <div className={cn(['col8', cls.suptitle])}>
                {suptitle}
            </div>
            }
            <div className={cn(['col8', cls.title])}>
                {title}
            </div>
            {subtitle &&
            <div className={cn(['col8', cls.subtitle])}>
                {subtitle}
            </div>
            }

            <div className={cn(['col12', cls.space])}>
                <div className={cls.image} style={{
                    backgroundImage: `url(${image})`,
                    backgroundPosition: imagePosition === 'center' ? 'center center' : 'center bottom',
                    maxHeight: imageMaxHeight || 350,
                    top: imagePosition === 'center' ? 0 : 'unset',
                    bottom: imagePosition === 'center' ? 0 : 'var(--element-gap)',
                    margin: imagePosition === 'center' ? 'auto' : '0 autox',
                }}
                />
            </div>
            <div className={cn(['col8', cls.footer])}>
                {footer}
            </div>
        </div>
        <div className={cls.wave}>
            <img src={img_wave} alt={'wave'}/>
        </div>
    </div>
)