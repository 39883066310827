import {applySnapshot, types as t,} from 'mobx-state-tree';

export const initialBoardingStore = {
  showCookie: true,
};

export const BoardStore = t
  .model({
    showCookie: t.boolean,
  })
  .actions((self) => ({
    clearState: () => {
      applySnapshot(self, initialBoardingStore);
    },
    setShowCookie: (value: boolean) => {
      self.showCookie = value;
    },
  }));
