import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
// @ts-ignore
import TopLoader from 'react-top-loader';
import { useQuery} from '@apollo/client';
import {Block} from 'blocks/Block/Block';
import {Blocks} from 'blocks/Blocks/Blocks';
import { Title } from 'blocks/Title/Title';
import {support, supportVariables} from 'graphql/__generated__/support';
import {QUERY_SUPPORT} from 'graphql/support';
import _ from 'lodash';

import {FAQ, IItem} from '../../blocks/FAQ/FAQ';
import {SmallCover} from '../../blocks/SmallCover/SmallCover';

export const Support = () => {

    const {i18n} = useTranslation()
    const [loading, setLoading] = useState<boolean>(true)

    const support = useQuery<support, supportVariables>(QUERY_SUPPORT, {
        variables: {
            locale: i18n.language
        },
        onCompleted: () => {
            setLoading(false)
        }
    })

    const faqOne: IItem[] = _.map(support.data?.support?.data?.attributes?.BlockOne, item => ({
        question: item?.Question!,
        answer: item?.Answer,
    }))
    const faqTwo: IItem[] = _.map(support.data?.support?.data?.attributes?.BlockTwo, item => ({
        question: item?.Question!,
        answer: item?.Answer,
    }))
    const faqThree: IItem[] = _.map(support.data?.support?.data?.attributes?.BlockThree, item => ({
        question: item?.Question!,
        answer: item?.Answer,
    }))
    const faqFour: IItem[] = _.map(support.data?.support?.data?.attributes?.BlockFour, item => ({
        question: item?.Question!,
        answer: item?.Answer,
    }))

    return (
        <>

            <TopLoader show={loading} color="#FF40B4"/>

            <SmallCover
                title={support.data?.support?.data?.attributes?.Title!}
            />

            <Blocks>

                <Block>
                    <Title className={'col6'}>{support.data?.support?.data?.attributes?.TitleOne}</Title>
                    <FAQ className={'col6'} items={faqOne}/>
                </Block>
                <Block>
                    <Title className={'col6'}>{support.data?.support?.data?.attributes?.TitleTwo}</Title>
                    <FAQ className={'col6'} items={faqTwo}/>
                </Block>
                <Block>
                    <Title className={'col6'}>{support.data?.support?.data?.attributes?.TitleThree}</Title>
                    <FAQ className={'col6'} items={faqThree}/>
                </Block>
                <Block>
                    <Title className={'col6'}>{support.data?.support?.data?.attributes?.TitleFour}</Title>
                    <FAQ className={'col6'} items={faqFour}/>
                </Block>

            </Blocks>

        </>
    )
}