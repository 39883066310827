import {gql} from '@apollo/client';

export const QUERY_PASS = gql`
    query pass($locale: I18NLocaleCode) {
        pass(locale: $locale) {
            data {
                attributes {
                    Title
                    Subtitle
                    Highlights {
                        id
                        Title
                        active
                    }
                    Fares {
                        id
                        Title
                        Cards {
                            id
                            Title
                            Description
                        }
                    }
                    FaqTitle
                    FAQ {
                        id
                        Question
                        Answer
                    }
                    FaqCaption
                    PaymentTitle
                    PaymentText
                    PaymentImage {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                    PaymentButton
                }
            }
            
        }
    }
`;