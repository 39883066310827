import { type Instance, type SnapshotIn, type SnapshotOut, types as t } from 'mobx-state-tree';

import { AuthStore, initialAuthStore } from './AuthStore';
import {BoardStore, initialBoardingStore} from './BoardingStore';

export const RootStore = t
    .model({
        hydrated: t.optional(t.boolean, false), // метка что состояние хранилища восстановлено из сохраненного в localstorage
        auth: t.optional(AuthStore, initialAuthStore),
        board: t.optional(BoardStore, initialBoardingStore),
    })
    .actions((self) => ({
        setHydrated: (value: boolean) => {
            self.hydrated = value;
        },
    }));

export interface IRootStoreModel extends Instance<typeof RootStore> {}

export interface IRootStoreModelSnapshotIn extends SnapshotIn<typeof RootStore> {}

export interface IRootStoreModelSnapshotOut extends SnapshotOut<typeof RootStore> {}

