import {gql} from '@apollo/client';

export const QUERY_ABOUT = gql`
    query about($locale: I18NLocaleCode) {
        about(locale: $locale) {
            data {
                attributes {
                    Title
                    Subtitle
                    Highlight
                    Image {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                    MissionTitle
                    MissionText
                }
            }
            
        }
    }
`;