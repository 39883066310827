import {gql} from '@apollo/client';

export const QUERY_HERO = gql`
    query hero($locale: I18NLocaleCode) {
        hero(locale: $locale) {
            data {
                attributes {
                    Title
                    Subtitle
                    TextBlock
                    Stories {
                        Image {
                            data {
                                attributes {
                                    url
                                }
                            }
                        }
                        Description
                    }
                }
            }
            
        }
    }
`;