import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import {COOKIE_POlICY, PRIVACY_POLICY, TERMINI_POLICY } from 'routes/routes'

import cls from './Footer.module.scss'

export const Footer = () => {

    const {t} = useTranslation()

    const menuItems = [
        {
            link: TERMINI_POLICY,
            label: t('Terms and Conditions'),
        },
        {
            link: PRIVACY_POLICY,
            label: t('Privacy Policy'),
        },
        {
            link: COOKIE_POlICY,
            label: t('Cookie Policy'),
        },
    ]

    return (
        <div className={cls.footer}>
            <div className={cls.links}>
                {_.map(menuItems, item => (
                    <Link key={item.link} to={item.link}>{item.label}</Link>
                ))}
            </div>
            <div className={cls.caption}>
                <div>
                    {'© 2024 RENTech L.L.C-FZ.'}
                </div>
                <div>
                    {'© 2024 AZIENDA NAZIONALE DI MICROMOBILITA\' S.R.L.'}
                </div>
            </div>
        </div>
    )
}