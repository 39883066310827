import React, {useEffect, useState} from 'react';
import cn from 'classnames'
import _ from 'lodash';

import {SuperSlider} from '../../components/SuperSlider/SuperSlider';

import {INewsCard, NewsCard} from './NewsCard'

import cls from './News.module.scss'

export interface ICards {
    items: INewsCard[]
    className?: string
}

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

export const News = ({items, className}:ICards) => {

    const [width, setWidth] = useState(getWindowDimensions().width);
    useEffect(() => {
        function handleResize() {
            setWidth(getWindowDimensions().width);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const data = _.map(items, (item, index) => (
        <NewsCard key={index} description={item.description} title={item.title} date={item.date} cover={item.cover}/>
    ))

    return (
        <div className={cn([cls.news, className])}>
            {width > 768 && data}
            {width <= 768 &&
                <div className={cls.wrapper}>
                    <SuperSlider>
                        {_.map(data, (item, index) => (
                            <div key={index} className={cls.slide}>
                                {item}
                            </div>
                        ))}
                    </SuperSlider>
                </div>
            }
        </div>
    )
}