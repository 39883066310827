import React from 'react';
import {BlocksRenderer} from '@strapi/blocks-react-renderer';
import {RootNode} from '@strapi/blocks-react-renderer/dist/BlocksRenderer';
import cn from 'classnames'

import {imageUrl} from 'components/imageUrl';

import cls from './Stories.module.scss'

export interface IStory {
    image: string
    description: RootNode[]
    className?: string
}

export const Story = ({image, description, className}:IStory) => (
    <div className={cn([cls.story, className])}>

        <div className={cls.image} style={{backgroundImage: `url(${imageUrl(image)})`}}></div>
        <div className={cn([cls.description, 'StrapiRichBlocks'])}><BlocksRenderer content={description} /></div>
    </div>
)