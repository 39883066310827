import React from 'react';
import cn from 'classnames'

import cls from './Caption.module.scss'

interface ICaption {
    children?: React.ReactNode
    className?: string
}

export const Caption = ({children, className}:ICaption) => (
    <div className={cn([cls.caption, className])}>
        {children}
    </div>
)