import {Button, ConfigProvider} from 'antd';

export const YellowButton = (props:any) => (
    <ConfigProvider theme={{
        token: {
            colorPrimary: '#EBE717',
            colorTextLightSolid: '#1F2A44',
            fontFamily: 'Open Sans, sans-serif',
            fontSize: 16,
        },
    }}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Button {...props} type={'primary'}/>
    </ConfigProvider>
)