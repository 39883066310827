import {gql} from '@apollo/client';

export const QUERY_SCOOTER = gql`
    query scooter($locale: I18NLocaleCode) {
        scooter(locale: $locale) {
            data {
                attributes {
                    Title
                    ScooterHolder
                    ScooterLight
                    ScooterPower
                    ScooterSoftware
                    ScooterSuspension
                    ScooterTire
                    ScooterWeight
                }
            }
            
        }
    }
`;