import {gql} from '@apollo/client';

export const CREATE_BECOME_PARTNER = gql`
    mutation createBecomePartner($data: BecomePartnerInput!) {
        createBecomePartner(data: $data) {
            data {
                id
            }
        }
    }
`;