import React from 'react';
import cn from 'classnames'

import cls from './Image.module.scss'

interface IImage {
    src?: string
    className?: string
    cover?: boolean
    toll?: boolean
}

export const Image = ({src, className, cover, toll}:IImage) => (
    <div className={cn([cls.image, className, toll ? cls.tall : cls.default])}
         style={{
             ...src && {backgroundImage: `url(${src})`},
             backgroundSize: cover ? 'cover' : 'contain'
    }}/>
)