import React, { useState } from 'react';
import {useTranslation} from 'react-i18next';
// @ts-ignore
import TopLoader from 'react-top-loader';
import { useQuery} from '@apollo/client';
import {Block} from 'blocks/Block/Block';
import {Blocks} from 'blocks/Blocks/Blocks';
import { Cover } from 'blocks/Cover/Cover';
import { Text } from 'blocks/Text/Text';
import _ from 'lodash';

import {Stories} from '../../blocks/Stories/Stories';
import {IStory} from '../../blocks/Stories/Story';
import {hero, heroVariables} from '../../graphql/__generated__/hero';
import {QUERY_HERO} from '../../graphql/hero';

import bg from './heroes_cover_bg.png'
import img from './heroes_cover_image.png'
export const Heroes = () => {

    const {i18n} = useTranslation()

    const [loading, setLoading] = useState<boolean>(true)
    const hero = useQuery<hero, heroVariables>(QUERY_HERO, {
        variables: {
            locale: i18n.language
        },
        onCompleted: () => {
            setLoading(false)
        }
    })

    // console.log(setLoading(false))

    const stories:IStory[] = _.map(hero.data?.hero?.data?.attributes?.Stories, item => ({
        image: item?.Image?.data?.attributes?.url!,
        description: item?.Description!,
    }))

    return (
        <>

            <TopLoader show={loading} color="#FF40B4"/>

            <Cover
                title={hero.data?.hero?.data?.attributes?.Title!}
                subtitle={hero.data?.hero?.data?.attributes?.Subtitle!}
                bg={bg}
                image={img}
                imagePosition={'bottom'}
            />

            <Blocks>

                <Block>
                    <Text className={'col6'}>{hero.data?.hero?.data?.attributes?.TextBlock!}</Text>
                </Block>

                <Block>
                    <Stories items={stories} className={'col12'}/>
                </Block>

            </Blocks>

        </>
    )
}