export const ROOT = '';
export const HOME = '/';
export const PARTNER = '/partner';
export const PASS = '/pass';
export const SUPPORT = '/support';
export const ABOUT = '/about';
export const HEROES = '/heroes';
export const VEHICLES = '/vehicles';
export const SUCCESS_PARTNER = '/partner/success';

export const TERMINI_POLICY = '/termini';
export const TERMINI_POLICY_EL = '/termini-el';
export const TERMINI_POLICY_EN = '/termini-en';
export const TERMINI_POLICY_IT = '/termini-it';

export const PRIVACY_POLICY = '/privacy-policy';
export const PRIVACY_POLICY_EL = '/privacy-policy-el';
export const PRIVACY_POLICY_EN = '/privacy-policy-en';
export const PRIVACY_POLICY_IT = '/privacy-policy-it';
export const SUCCESS_DELETE_ACCOUNT = '/privacy-policy/success';

export const COOKIE_POlICY = '/cookie-policy';
export const COOKIE_POlICY_EL = '/cookie-policy-el';
export const COOKIE_POlICY_EN = '/cookie-policy-en';
export const COOKIE_POlICY_IT = '/cookie-policy-it';

export const APP_AppStore = 'https://apps.apple.com/us/app/moow-rent/id6476504797';
export const APP_GooglePlay = 'https://play.google.com/store/apps/details?id=app.moow.rent';

