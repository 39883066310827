import React from 'react';
import cn from 'classnames'

import cls from './Title.module.scss'

interface ITitle {
    children?: React.ReactNode
    className?: string
}

export const Title = ({children, className}:ITitle) => (
    <div className={cn([cls.title, className])}>
        {children}
    </div>
)