import React, {useEffect, useState} from 'react';
import cn from 'classnames'
import _ from 'lodash';

import {SuperSlider} from '../../components/SuperSlider/SuperSlider';

import {ISolid, Solid} from './Solid'

import cls from './Solids.module.scss'

export interface ISolids {
    items: ISolid[]
    className?: string
}

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

export const Solids = ({items, className}:ISolids) => {

    const [width, setWidth] = useState(getWindowDimensions().width);
    useEffect(() => {
        function handleResize() {
            setWidth(getWindowDimensions().width);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const data = _.map(items, (item, index) => (
        <Solid key={index} content={item.content} title={item.title}/>
    ))

    return (
        <div className={cn([cls.solids, className])}>
            {width > 768 && data}
            {width <= 768 &&
                <div className={cls.wrapper}>
                    <SuperSlider>
                        {_.map(data, (item, index) => (
                            <div key={index} className={cls.slide}>
                                {item}
                            </div>
                        ))}
                    </SuperSlider>
                </div>
            }
        </div>
    )
}