import React from 'react';
import cn from 'classnames'
import _ from 'lodash';

import {ISquare, Square} from './Square'

import cls from './Chess.module.scss'

export interface ICards {
    items: ISquare[]
    className?: string
}

export const Chess = ({items, className}:ICards) => (
    <div className={cn([cls.chess, className])}>
        {_.map(items, (item, index) => (
            <Square key={index} content={item.content} list={item.list} image={item.image}/>
        ))}
    </div>
)