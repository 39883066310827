import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
// @ts-ignore
import TopLoader from 'react-top-loader';
import {useQuery} from '@apollo/client';
import {Block} from 'blocks/Block/Block';
import {Blocks} from 'blocks/Blocks/Blocks';
import {Image} from 'blocks/Image/Image';
import {SmallCover} from 'blocks/SmallCover/SmallCover';
import {Text} from 'blocks/Text/Text';
import {Title} from 'blocks/Title/Title';
import cn from 'classnames';
import {about, aboutVariables} from 'graphql/__generated__/about';
import {QUERY_ABOUT} from 'graphql/about';
import logo from 'layout/Header/moow-logo.svg'

import {imageUrl} from 'components/imageUrl';

import img_gray_wave from './gray-wave.svg';
import img_yellow_wave from './yellow-wave.svg';

import cls from './About.module.scss'


export const About = () => {

    const {i18n} = useTranslation()
    const [loading, setLoading] = useState<boolean>(true)

    const about = useQuery<about, aboutVariables>(QUERY_ABOUT, {
        variables: {
            locale: i18n.language
        },
        onCompleted: () => {
            setLoading(false)
        }
    })

    return (
        <>

            <TopLoader show={loading} color="#FF40B4"/>

            <SmallCover
                title={about.data?.about?.data?.attributes?.Title!}
                subtitle={about.data?.about?.data?.attributes?.Subtitle!}
            />

            <Blocks>

                <Block>
                    <img src={logo} alt={'Logo MOOW'} className={cls.image}/>
                    <Title className={'col6'}>{about.data?.about?.data?.attributes?.Highlight}</Title>
                    <Image src={imageUrl(about.data?.about?.data?.attributes?.Image?.data?.attributes?.url)}/>
                </Block>

            </Blocks>

            <div className={cls.wave}>
                <img src={img_yellow_wave} alt={'wave'}/>
            </div>
            <Blocks className={cls.yellow}>
                <Block>
                    <Title className={'col6'}>{about.data?.about?.data?.attributes?.MissionTitle}</Title>
                    <Text className={'col6'}>{about.data?.about?.data?.attributes?.MissionText}</Text>
                </Block>
            </Blocks>
            <div className={cn([cls.wave, cls.yellow])}>
                <img src={img_gray_wave} alt={'wave'}/>
            </div>

        </>
    )
}