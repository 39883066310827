import React from 'react';
import cn from 'classnames'

import cls from './Text.module.scss'

interface IText {
    children?: React.ReactNode
    className?: string
}

export const Text = ({children, className}:IText) => (
    <div className={cn([cls.text, className])}>
        {children}
    </div>
)