import { Icon } from 'blocks/Icon/Icon'

import cls from './App.module.scss'

interface IApp {
    icon?: string
    name?: string
    description?: string
}

export const App = ({icon, name, description}:IApp) => (
    <div className={cls.app}>
        <Icon src={icon!} width={64} height={64}/>
        <div className={cls.text}>
            <div className={cls.name}>{name}</div>
            <div>{description}</div>
        </div>

    </div>
)