import {gql} from '@apollo/client';

export const COOKIEPOLICY = gql`
    query cookiePolicy($locale: I18NLocaleCode) {
        cookiePolicy(locale: $locale) {
            data {
                attributes {
                    Title
                    Content
                }
            }
            
        }
    }
`;