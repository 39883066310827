import {gql} from '@apollo/client';

export const HOME = gql`
    query home($locale: I18NLocaleCode) {
        home(locale: $locale) {
            data {
                attributes {
                    CoverTitle
                    RuleTitle
                    RuleCards {
                        id
                        Title
                        Description
                        Image {
                            data {
                                attributes {
                                    url
                                }
                            }
                        }
                    }
                    Image {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                    SafetyTitle
                    SafetyChess {
                        id
                        Image {
                            data {
                                attributes {
                                    url
                                }
                            }
                        }
                        List {
                            Icon {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                            Text
                        }
                    }
                    SchoolTitle
                    SchoolButton
                    AppTitle
                    AppIOSText
                    AppAndroidText
                    AppButton
                }
            }
            
        }
    }
`;