import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {Block} from 'blocks/Block/Block';
import {Blocks} from 'blocks/Blocks/Blocks';
import { Icon } from 'blocks/Icon/Icon';
import {Text} from 'blocks/Text/Text';
import {Title} from 'blocks/Title/Title';
import {successDeleteAccount, successDeleteAccountVariables} from 'graphql/__generated__/successDeleteAccount';
import { SUCCESDELETEACCOUNT } from 'graphql/success-delete-account';
import {HOME} from 'routes/routes';

import { imageUrl } from 'components/imageUrl';
import {YellowButton} from 'components/YellowButton/YellowButton';

export const SuccessDeleteAccount = () => {

    const {i18n} = useTranslation()
    const navigate = useNavigate()


    const success = useQuery<successDeleteAccount,successDeleteAccountVariables>(SUCCESDELETEACCOUNT, {
        variables: {
            locale: i18n.language
        },
    })

    return (
        <div>

            <Blocks>

                <Block>
                    <Icon src={imageUrl(success.data?.successDeleteAccount?.data?.attributes?.Image?.data?.attributes?.url!)}/>
                    <Title>{success.data?.successDeleteAccount?.data?.attributes?.Title!}</Title>
                    <Text className={'col4'}>{success.data?.successDeleteAccount?.data?.attributes?.Text!}</Text>
                    <YellowButton size={'large'} onClick={() => navigate(HOME)}>{success.data?.successDeleteAccount?.data?.attributes?.Button!}</YellowButton>
                </Block>

            </Blocks>


        </div>
    )
}