import {gql} from '@apollo/client';

export const PRIVACYPOLICY = gql`
    query privacyPolicy($locale: I18NLocaleCode) {
        privacyPolicy(locale: $locale) {
            data {
                attributes {
                    FormTitle
                    FormSubtitle
                    FormButton
                    FormCaption
                    Title
                    Content
                }
            }
            
        }
    }
`;