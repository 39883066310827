import React from 'react';
import {Popover} from 'antd';
import cn from 'classnames'

import cls from './Vehicles.module.scss';

export interface IDot {
    key?: string
    x: number
    y: number
    description: string
    active: boolean
    disabled: boolean
    onClick: () => void
}

export const Dot = ({x, y, description, disabled, onClick}: IDot) => (
    <Popover content={!disabled && <p>{description}</p>} trigger={'click'} onOpenChange={onClick} className={cls.popover}>
        <div className={cn([cls.dot, disabled && cls.disabled])} style={{left: x, top: y}} onClick={onClick}>
            <div className={cls.radius}/>
            <div className={cls.point}/>
        </div>
    </Popover>
)