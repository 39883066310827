import React from 'react';
import cn from 'classnames';

import img_wave from './wave.svg'

import cls from './SmallCover.module.scss'

interface ISmallCover {
    title?: string
    suptitle?: string
    subtitle?: string
    children?: React.ReactNode
}



export const SmallCover = ({title, suptitle, subtitle, children,}: ISmallCover) => (
    <div className={cls.cover}>
        {(suptitle || title || subtitle || children) &&
        <div className={cls.content}>
            {suptitle &&
            <div className={cn(['col8', cls.suptitle])}>
                {suptitle}
            </div>
            }
            {title &&
            <div className={cn(['col8', cls.title])}>
                {title}
            </div>
            }
            {subtitle &&
            <div className={cn(['col8', cls.subtitle])}>
                {subtitle}
            </div>
            }
            {children &&
            <div className={cn(['col8', cls.footer])}>
                {children}
            </div>
            }
        </div>
        }
        <div className={cls.wave}>
            <img src={img_wave} alt={'wave'}/>
        </div>
    </div>
)