import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
// @ts-ignore
import TopLoader from 'react-top-loader';
import {RightOutlined} from '@ant-design/icons';
import {useQuery} from '@apollo/client';
import {Block} from 'blocks/Block/Block';
import {Blocks} from 'blocks/Blocks/Blocks';
import {Cover} from 'blocks/Cover/Cover';
import {IFare} from 'blocks/Fares/Fare';
import {Title} from 'blocks/Title/Title';
import cn from 'classnames'
import _ from 'lodash';

import {imageUrl} from 'components/imageUrl';

import {Caption} from '../../blocks/Caption/Caption';
import {FAQ, IItem} from '../../blocks/FAQ/FAQ';
import {IFareHighlight} from '../../blocks/FareHighlights/FareHighlight';
import {FareHighlights} from '../../blocks/FareHighlights/FareHighlights';
import {Fares} from '../../blocks/Fares/Fares';
import {getMobileOperatingSystem} from '../../components/detectUserAgent';
import {YellowButton} from '../../components/YellowButton/YellowButton';
import {pass, passVariables} from '../../graphql/__generated__/pass';
import {QUERY_PASS} from '../../graphql/pass';
import {APP_AppStore, APP_GooglePlay} from '../../routes/routes';

import img_cover from './img/img_cover_pass.png'

import cls from './Pass.module.scss'

export const Pass = () => {

    const {i18n} = useTranslation()
    const [loading, setLoading] = useState<boolean>(true)

    const pass = useQuery<pass, passVariables>(QUERY_PASS, {
        variables: {
            locale: i18n.language
        },
        onCompleted: () => {
            setLoading(false)
        }
    })

    const highlights: IFareHighlight[] = _.map(pass.data?.pass?.data?.attributes?.Highlights, item => ({
        title: item?.Title!,
        active: item?.active!,
    }))

    const fares: IFare[] = _.map(pass.data?.pass?.data?.attributes?.Fares, item => ({
        title: item?.Title!,
        cards: _.map(item?.Cards, card => ({
            title: card?.Title!,
            content: card?.Description!,
        })),
    }))

    const faq: IItem[] = _.map(pass.data?.pass?.data?.attributes?.FAQ, item => ({
        question: item?.Question!,
        answer: item?.Answer,
    }))

    const appLink = getMobileOperatingSystem() === 'Android' ? APP_GooglePlay : APP_AppStore

    return (
        <>

            <TopLoader show={loading} color="#FF40B4"/>

            <Cover
                subtitle={pass.data?.pass?.data?.attributes?.Subtitle!}
                title={pass.data?.pass?.data?.attributes?.Title!}
                image={img_cover}
                imageMaxHeight={500}
            />

            <Blocks>

                <Block>
                    <FareHighlights items={highlights} className={'col12'}/>
                </Block>

                <Block>
                    <Fares items={fares} className={'col12'}/>
                </Block>

                <Block>
                    <Title className={'col6'}>{pass.data?.pass?.data?.attributes?.FaqTitle}</Title>
                    <FAQ className={'col6'} items={faq}/>
                    <Caption className={'col6'}>{pass.data?.pass?.data?.attributes?.FaqCaption}</Caption>
                </Block>

                <Block>
                    <Title className={'col6'}>{pass.data?.pass?.data?.attributes?.PaymentTitle}</Title>
                    <div className={cn([cls.wrapper, 'col6'])}>
                        <div className={cls.payment}>
                            <div className={cls.text}>{pass.data?.pass?.data?.attributes?.PaymentText}</div>
                            <div className={cls.image}><img
                                src={imageUrl(pass.data?.pass?.data?.attributes?.PaymentImage?.data?.attributes?.url!)}
                                alt={'Payment options'}/></div>
                        </div>
                        <a href={appLink} target={'_blank'} rel={'noreferrer'} className={'block'}>
                            <YellowButton
                                size={'large'}
                                className={cls.button}
                                icon={<RightOutlined/>}
                            >{pass.data?.pass?.data?.attributes?.PaymentButton}</YellowButton>
                        </a>
                    </div>
                </Block>


            </Blocks>

        </>
    )
}