import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {BlocksRenderer} from '@strapi/blocks-react-renderer';
import {RootNode} from '@strapi/blocks-react-renderer/dist/BlocksRenderer';
import {Modal} from 'antd';
import cn from 'classnames'
import dayjs from 'dayjs'

import {imageUrl} from '../../components/imageUrl';

import cls from './News.module.scss'

export interface INewsCard {
    cover: string
    date: dayjs.Dayjs
    title: string
    description: RootNode[]
    className?: string
}

export const NewsCard = ({title, description, date, cover, className}:INewsCard) => {

    const {t} = useTranslation()

    const [modalOpen, setModalOpen] = useState<boolean>(false)

    const toggleModalOpen = () => {
        setModalOpen(!modalOpen)
    }

    return (
        <div className={cn([cls.newsCard, className])}>
            <div className={cls.cover} style={{backgroundImage: `url(${imageUrl(cover)})`}}></div>
            <div className={cls.headline}>
                <div className={cn([cls.title, 'bold'])}>{title}</div>
                <div className={cn([cls.date, 'caption'])}>{date.format('DD.MM.YYYY')}</div>
            </div>
            <div className={cn([cls.description, 'StrapiRichBlocks'])}><BlocksRenderer content={description} /></div>
            <div className={cls.link} onClick={toggleModalOpen}>
                {t('Read more')}
            </div>

            <Modal open={modalOpen} onCancel={() => setModalOpen(false)} footer={null}>
                <div className={cls.modal}>
                    <div className={cls.cover} style={{backgroundImage: `url(${imageUrl(cover)})`}}></div>
                    <div className={cls.headline}>
                        <div className={cn([cls.title, 'bold'])}>{title}</div>
                        <div className={cn([cls.date, 'caption'])}>{date.format('DD.MM.YYYY')}</div>
                    </div>
                    <div className={cn(['StrapiRichBlocks'])}><BlocksRenderer content={description} /></div>
                </div>
            </Modal>

        </div>
    )
}