import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from '@apollo/client';
import {type BlocksContent,BlocksRenderer} from '@strapi/blocks-react-renderer';
import {Block} from 'blocks/Block/Block';
import {Blocks} from 'blocks/Blocks/Blocks';
import {Title} from 'blocks/Title/Title';
import {cookiePolicy, cookiePolicyVariables} from 'graphql/__generated__/cookiePolicy';
import {COOKIEPOLICY} from 'graphql/cookie-policy';



export const CookiePolicyEl = () => {

    const {i18n} = useTranslation()
    useEffect( () => {
        if (i18n.language !== 'el') {
            i18n.changeLanguage('el')
        }
    }, [i18n])

    const [content, setContent] = useState<BlocksContent>([])

    const cookiePolicy = useQuery<cookiePolicy,cookiePolicyVariables>(COOKIEPOLICY, {
        variables: {
            locale: i18n.language
        },
        onCompleted: (data) => {
            setContent(data.cookiePolicy?.data?.attributes?.Content! || [])
        }
    })

    return (
        <div>

            <Blocks>

                <Block>
                    <Title>{cookiePolicy.data?.cookiePolicy?.data?.attributes?.Title!}</Title>
                    <div className={'col8 StrapiRichBlocks'}>
                        <BlocksRenderer content={content} />
                    </div>
                </Block>

            </Blocks>


        </div>
    )
}