import React from 'react';
import cn from 'classnames'
import _ from 'lodash';

import {FareHighlight, IFareHighlight} from './FareHighlight'

import cls from './FareHighlights.module.scss'

export interface IFareHighlights {
    items: IFareHighlight[]
    className?: string
}

export const FareHighlights = ({items, className}:IFareHighlights) => {

    const data = _.map(items, (item, index) => (
        <FareHighlight key={index} title={item.title} active={item.active}/>
    ))

    return (
        <div className={cn([cls.cards, className])}>
            {data}
        </div>
    )
}