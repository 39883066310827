import React from 'react';
import {BlocksRenderer} from '@strapi/blocks-react-renderer';
import { RootNode } from '@strapi/blocks-react-renderer/dist/BlocksRenderer';
import type { CollapseProps } from 'antd';
import {Collapse} from 'antd';
import cn from 'classnames'
import _ from 'lodash';

export interface IItem {
    question: string
    answer: RootNode[]
}
export interface IFAQ {
    items: IItem[]
    className?: string
}

export const FAQ = ({items, className}:IFAQ) => {

    const data:CollapseProps['items'] = _.map(items, (item, index) => ({
        key: index,
        label: item.question,
        children: <div className={'StrapiRichBlocks'}><BlocksRenderer content={item.answer} /></div>
    }))

    return (
        <div className={cn([className])}>
            <Collapse items={data} accordion/>
        </div>
    )
}