import {gql} from '@apollo/client';

export const CREATE_DELETE_ACCOUNT = gql`
    mutation createDeleteAccount($data: DeleteAccountInput!) {
        createDeleteAccount(data: $data) {
            data {
                id
            }
        }
    }
`;