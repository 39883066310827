import {gql} from '@apollo/client';

export const SUCCESDELETEACCOUNT = gql`
    query successDeleteAccount($locale: I18NLocaleCode) {
        successDeleteAccount(locale: $locale) {
            data {
                attributes {
                    Image {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                    Title
                    Text
                    Button
                }
            }
            
        }
    }
`;