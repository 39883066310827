import React from 'react';
import cn from 'classnames'
import _ from 'lodash';

import {ICard} from '../Cards/Card';

import cls from './Chess.module.scss'

export interface IList {
    icon?: string
    text: string
}

export interface ISquare {
    image?: string
    content?: ICard
    list?: IList[]
    className?: string
}

export const Square = ({image, content, list, className}:ISquare) => (
    <div className={cn([cls.square, className])}>
        <div className={cls.image} style={{...image && {backgroundImage: `url(${image})`}}}/>
        <div className={cls.wrapper}>
            {content &&
                <div className={cls.content}>
                    <div className={'bold'}>{content.title}</div>
                    <div>{content.content}</div>
                </div>
            }
            {list &&
                <div className={cls.list}>
                    {_.map(list, (item, index) => (
                        <div key={index} className={cls.item}>
                            {cls.icon && <img className={cls.icon} src={item.icon} alt={'icon'}/>}
                            <span className={cls.text}>{item.text}</span>
                        </div>
                    ))}
                </div>
            }
        </div>
    </div>
)