import {gql} from '@apollo/client';

export const QUERY_NEWS = gql`
    query news ($filters: NewFiltersInput, $locale: I18NLocaleCode, $pagination: PaginationArg = {}, $sort: [String] = []) {
        news (filters: $filters, locale: $locale, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    Title
                    PublishAt
                    Description
                    Cover {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                    Media {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;