import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from '@apollo/client';
import {type BlocksContent,BlocksRenderer} from '@strapi/blocks-react-renderer';
import {Block} from 'blocks/Block/Block';
import {Blocks} from 'blocks/Blocks/Blocks';
import {term, termVariables} from 'graphql/__generated__/term';
import {TERM} from 'graphql/termini';

import {Title} from '../../blocks/Title/Title';

export const TerminiIt = () => {

    const {i18n} = useTranslation()
    useEffect( () => {
        if (i18n.language !== 'it') {
            i18n.changeLanguage('it')
        }
    }, [i18n])

    const [content, setContent] = useState<BlocksContent>([])

    const termini = useQuery<term,termVariables>(TERM, {
        variables: {
            locale: i18n.language
        },
        onCompleted: (data) => {
            setContent(data.term?.data?.attributes?.Content! || [])
        }
    })

    return (
        <div>
            
            <Blocks>

                <Block>
                    <Title>{termini.data?.term?.data?.attributes?.Title!}</Title>
                    <div className={'col8 StrapiRichBlocks'}>
                        <BlocksRenderer content={content} />
                    </div>
                </Block>

            </Blocks>


        </div>
    )
}