import React from 'react';
import cn from 'classnames'

import cls from './Solids.module.scss'

export interface ISolid {
    title: string
    content: string
    className?: string
}

export const Solid = ({title, content, className}:ISolid) => (
    <div className={cn([cls.solid, className])}>
        <div className={cls.title}>{title}</div>
        <div>{content}</div>
    </div>
)