import React from 'react';
import cn from 'classnames'

import cls from './Highlights.module.scss'

export interface IHighlight {
    image?: string
    title: string
    content: string
    className?: string
}

export const Highlight = ({image, title, content, className}:IHighlight) => (
    <div className={cn([cls.highlight, className])}>
        <div className={cls.image} style={{...image && {backgroundImage: `url(${image})`}}}/>
        <div className={'bold'}>{title}</div>
        <div>{content}</div>
    </div>
)