import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
// @ts-ignore
import TopLoader from 'react-top-loader';
import {DownOutlined} from '@ant-design/icons';
import {useMutation, useQuery} from '@apollo/client';
import {Button, Form, Input, Select} from 'antd';
import PhoneInput from 'antd-phone-input';
import {Block} from 'blocks/Block/Block';
import {Blocks} from 'blocks/Blocks/Blocks';
import {ICard} from 'blocks/Cards/Card';
import {Cards} from 'blocks/Cards/Cards';
import {Chess} from 'blocks/Chess/Chess';
import {ISquare} from 'blocks/Chess/Square';
import {Cover} from 'blocks/Cover/Cover';
import {Image} from 'blocks/Image/Image';
import {ISolid} from 'blocks/Solids/Solid';
import {Solids} from 'blocks/Solids/Solids';
import {Text} from 'blocks/Text/Text';
import {Title} from 'blocks/Title/Title';
import cn from 'classnames'
import {
    createBecomePartner,
    createBecomePartnerVariables
} from 'graphql/__generated__/createBecomePartner';
import {partner, partnerVariables} from 'graphql/__generated__/partner';
import {CREATE_BECOME_PARTNER} from 'graphql/become-partner';
import {PARTNER} from 'graphql/partner';
import _ from 'lodash';
import {SUCCESS_PARTNER} from 'routes/routes';

import {imageUrl} from 'components/imageUrl';
import {YellowButton} from 'components/YellowButton/YellowButton';

import img_cover_scooter from './img/img_cover_scooter.png'

import cls from './Partner.module.scss'

export const Partner = () => {

    const {t, i18n} = useTranslation()
    const navigate = useNavigate()

    const [loading, setLoading] = useState<boolean>(true)

    const partner = useQuery<partner, partnerVariables>(PARTNER, {
        variables: {
            locale: i18n.language
        },
        onCompleted: () => {
            setLoading(false)
        }
    })

    const [createBecomePartner, createBecomePartnerData] = useMutation<createBecomePartner, createBecomePartnerVariables>(CREATE_BECOME_PARTNER)

    const advantages: ICard[] = _.map(partner.data?.partner?.data?.attributes?.AdvantagesCards, item => ({
        title: item?.Title!,
        content: item?.Description!,
    }))

    const getCards: ICard[] = _.map(partner.data?.partner?.data?.attributes?.GetCards, item => ({
        title: item?.Title!,
        content: item?.Description!,
    }))

    const earn: ISquare[] = _.map(partner.data?.partner?.data?.attributes?.EarnChess, item => ({
        image: imageUrl(item?.Image?.data?.attributes?.url!),
        content: {
            title: item?.Card?.Title!,
            content: item?.Card?.Description!,
        }
    }))

    const fares: ISolid[] = _.map(partner.data?.partner?.data?.attributes?.FareCards, item => ({
        title: item?.Title!,
        content: item?.Description!,
    }))

    const onFinish = async (data: any) => {
        await createBecomePartner({
            variables: {
                data: {
                    name: data.name,
                    email: data.email,
                    phone: `+${data.phone.countryCode} (${data.phone.areaCode}) ${data.phone.phoneNumber}`,
                    city: data.city,
                    type: data.type,
                }
            },
            onCompleted: () => {
                navigate(SUCCESS_PARTNER)
            }
        })
    }
    // @ts-ignore
    const validator = (_, {valid}) => {
        // if (valid(true)) return Promise.resolve(); // strict validation
        if (valid(true)) return Promise.resolve(); // non-strict validation
        return Promise.reject(t('Invalid format'));
    }

    const goToForm = (e:any) => {
        const form = document.getElementById('form');
        e.preventDefault();  // Stop Page Reloading
        form && form.scrollIntoView({ behavior: 'smooth', block: 'start'});
    }

    return (
        <div className={cls.page}>

            <TopLoader show={loading} color="#FF40B4"/>

            <Cover
                suptitle={partner.data?.partner?.data?.attributes?.CoverSubtitle!}
                title={partner.data?.partner?.data?.attributes?.CoverTitle!}
                image={img_cover_scooter}
                imageMaxHeight={500}
                imagePosition={'bottom'}
                footer={<Button onClick={goToForm} className={'col4'} type={'primary'}
                                size={'large'}>{partner.data?.partner?.data?.attributes?.CoverButton!}
                    <DownOutlined/></Button>}
            />

            <Blocks>

                <Block>
                    <Text className={'col8'}>
                        {partner.data?.partner?.data?.attributes?.TextBlockOne}
                    </Text>
                </Block>

                <Block>
                    <Title>
                        {partner.data?.partner?.data?.attributes?.AdvantagesTitle!}
                    </Title>
                    <Image
                        src={imageUrl(partner.data?.partner?.data?.attributes?.AdvantagesImage?.data?.attributes?.url!)}
                        cover/>
                    <Cards items={advantages} className={'col12'}/>
                </Block>

                <Block>
                    <Text className={'col8'}>
                        {partner.data?.partner?.data?.attributes?.TextBlockTwo!}
                    </Text>
                    <YellowButton className={'col4'}
                                  size={'large'}
                                  onClick={goToForm}
                    >
                        {partner.data?.partner?.data?.attributes?.TextBlockTwoButton!} <DownOutlined/>
                    </YellowButton>
                </Block>

                <Block>
                    <Title>
                        {partner.data?.partner?.data?.attributes?.GetTitle!}
                    </Title>
                    <Image src={imageUrl(partner.data?.partner?.data?.attributes?.GetImage?.data?.attributes?.url!)}/>
                    <Cards items={getCards} className={'col12'}/>
                </Block>

                <Block>
                    <Title>
                        {partner.data?.partner?.data?.attributes?.EarnTitle!}
                    </Title>
                    <Chess items={earn} className={'col8'}/>
                </Block>

                <Block>
                    <Title>
                        {partner.data?.partner?.data?.attributes?.FareTitle!}
                    </Title>
                    <Solids items={fares} className={'col12'}/>
                </Block>

                <Block>
                    <Text className={'col8'}>
                        {partner.data?.partner?.data?.attributes?.TextBlockThree!}
                    </Text>
                    <YellowButton className={'col4'}
                                  size={'large'}
                                  onClick={goToForm}
                    >
                        {partner.data?.partner?.data?.attributes?.TextBlockThreeButton!} <DownOutlined/>
                    </YellowButton>
                </Block>

            </Blocks>

            <section id={'form'}>
                <div className={cls.formBlock}>

                    <h2>{partner.data?.partner?.data?.attributes?.FormTitle!}</h2>

                    <Form layout={'vertical'} className={cls.form} size={'large'} onFinish={onFinish}>
                        <Form.Item name={'name'} label={t('Name')}
                                   rules={[
                                       {required: true, message: `${t('Please fill the field')}`},
                                   ]}
                        >
                            <Input placeholder={'Marco Polo'}/>
                        </Form.Item>
                        <Form.Item name={'email'} label={t('Email')}
                                   rules={[
                                       {required: true, message: `${t('Please fill the field')}`},
                                       {type: 'email', message: `${t('Invalid format')}`},
                                   ]}
                        >
                            <Input placeholder={'name@example.com'}/>
                        </Form.Item>
                        <Form.Item name={'phone'} label={t('Phone')}
                                   rules={[
                                       {required: true, message: `${t('Please fill the field')}`},
                                       {validator}
                                   ]}
                        >
                            <PhoneInput enableSearch preferredCountries={['it']} country={'it'}/>
                        </Form.Item>
                        <Form.Item name={'city'} label={t('City')}
                                   rules={[
                                       {required: true, message: `${t('Please fill the field')}`},
                                   ]}
                        >
                            <Input placeholder={'La Spezia'}/>
                        </Form.Item>
                        <Form.Item name={'type'} label={t('Franchise Form')}
                                   rules={[
                                       {required: true, message: `${t('Please fill the field')}`},
                                   ]}
                        >
                            <Select placeholder={t('Please, select...')} options={[
                                {value: 'MINI', label: t('Mini')},
                                {value: 'STANDART', label: t('Standart')},
                                {value: 'MAX', label: t('Max')},
                                {value: 'RENT', label: t('Rent')},
                            ]}/>
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType={'submit'} type={'primary'} block
                                    loading={createBecomePartnerData.loading}>{partner.data?.partner?.data?.attributes?.FormButton!}</Button>
                        </Form.Item>
                    </Form>

                    <div className={cn(['col12', cls.line])}/>

                </div>
            </section>


        </div>
    )
}