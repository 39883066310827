import React from 'react';
import cn from 'classnames';

import cls from './Blocks.module.scss'

interface IBlocks {
    children?: React.ReactNode
    className?: string

}

export const Blocks = ({children, className}:IBlocks) => (
    <div className={cn([cls.blocks, className])}>
        {children}
    </div>
)