import React from 'react';
// eslint-disable-next-line import/no-named-as-default
import CookieConsent from 'react-cookie-consent';
import ReactGA from 'react-ga4';
import {useTranslation} from 'react-i18next';
import {provider, useInstance} from 'react-ioc';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import { YMInitializer } from 'react-yandex-metrika';
import {ApolloClient, ApolloProvider} from '@apollo/client';
import { ConfigProvider } from 'antd';
import ApolloClientDI from 'graphql/ApolloClientDI';
import {Layout} from 'layout/Layout/Layout';
import {observer} from 'mobx-react-lite';
import {CookiePolicy} from 'pages/CookiePolicy/CookiePolicy';
import { CookiePolicyEl } from 'pages/CookiePolicy/CookiePolicyEl';
import { CookiePolicyIt } from 'pages/CookiePolicy/CookiePolicyIt';
import {Home} from 'pages/Home/Home';
import {Partner} from 'pages/Partner/Partner';
import {PrivacyPolicy} from 'pages/PrivacyPolicy/PrivacyPolicy';
import {SuccessPartner} from 'pages/SuccessPartner/SuccessPartner';
import {Termini} from 'pages/Termini/Termini';
import {
    ABOUT,
    COOKIE_POlICY, COOKIE_POlICY_EL, COOKIE_POlICY_EN, COOKIE_POlICY_IT, HEROES,
    HOME,
    PARTNER,
    PASS,
    PRIVACY_POLICY,
    PRIVACY_POLICY_EL,
    PRIVACY_POLICY_EN,
    PRIVACY_POLICY_IT,
    ROOT,
    SUCCESS_DELETE_ACCOUNT,
    SUCCESS_PARTNER,
    SUPPORT,
    TERMINI_POLICY, TERMINI_POLICY_EL, TERMINI_POLICY_EN, TERMINI_POLICY_IT, VEHICLES
} from 'routes/routes';
import {Store} from 'store/Store';
import StoreDI from 'store/StoreDI';

import { Metrika } from 'components/common/Metrika';
import ScrollToTop from 'components/common/ScrollToTop';

import {About} from '../pages/About/About';
import {CookiePolicyEn} from '../pages/CookiePolicy/CookiePolicyEn';
import {Heroes} from '../pages/Heroes/Heroes';
import {Pass} from '../pages/Pass/Pass';
import {PrivacyPolicyEl} from '../pages/PrivacyPolicy/PrivacyPolicyEl';
import {PrivacyPolicyEn} from '../pages/PrivacyPolicy/PrivacyPolicyEn';
import {PrivacyPolicyIt} from '../pages/PrivacyPolicy/PrivacyPolicyIt';
import {SuccessDeleteAccount} from '../pages/SuccessDeleteAccount/SuccessDeleteAccount';
import {Support} from '../pages/Support/Support';
import {TerminiEl} from '../pages/Termini/TerminiEl';
import {TerminiEn} from '../pages/Termini/TerminiEn';
import {TerminiIt} from '../pages/Termini/TerminiIt';
import {Vehicles} from '../pages/Vehicles/Vehicles';

import './App.css';

ReactGA.initialize('G-2JHC8P0Y26');


function App() {

    const apolloClient = useInstance(ApolloClient);

    const {t} = useTranslation();
    const {board} = useInstance(Store);

    return (
        <ApolloProvider client={apolloClient}>

            <CookieConsent
                visible={board.showCookie ? 'show' : 'hidden'}
                location="bottom"
                style={{ background: '#1F2A44', left: 'unset', right: 0, bottom: 0, fontSize: 16, padding: '16px 20px', gap: 16, justifyContent: 'center',}}
                contentStyle={{margin: 'unset', flex: 'unset'}}
                buttonStyle={{ background: '#EBE717', color: '#1F2A44', borderRadius: '8px', fontSize: 16, margin: 'unset', padding: '8px 16px'}}
                expires={150}
                buttonText={t('Okay, don\'t show yourself again')}
                onAccept={() => board.setShowCookie(false)}
            >
                    {t('We use cookies to give you the best experience on the site.')}
            </CookieConsent>

        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#FF40B4',
                    fontFamily: 'Open Sans, sans-serif',
                    fontSize: 16,
                },
            }}>
            <BrowserRouter>
                <ScrollToTop/>
                <YMInitializer accounts={[98378198]} options={{
                    clickmap: true,
                    trackLinks: true,
                    accurateTrackBounce: true,
                    webvisor: true
                }}/>
                <Metrika/>

                <Routes>
                    <Route path={ROOT} element={<Layout/>}>
                        <Route path={HOME} element={<Home/>}/>
                        <Route path={ABOUT} element={<About/>}/>
                        <Route path={PARTNER} element={<Partner/>}/>
                        <Route path={SUCCESS_PARTNER} element={<SuccessPartner/>}/>
                        <Route path={PASS} element={<Pass/>}/>
                        <Route path={SUPPORT} element={<Support/>}/>
                        <Route path={HEROES} element={<Heroes/>}/>
                        <Route path={VEHICLES} element={<Vehicles/>}/>

                        <Route path={TERMINI_POLICY} element={<Termini/>}/>
                        <Route path={TERMINI_POLICY_EL} element={<TerminiEl/>}/>
                        <Route path={TERMINI_POLICY_EN} element={<TerminiEn/>}/>
                        <Route path={TERMINI_POLICY_IT} element={<TerminiIt/>}/>

                        <Route path={COOKIE_POlICY} element={<CookiePolicy/>}/>
                        <Route path={COOKIE_POlICY_EL} element={<CookiePolicyEl/>}/>
                        <Route path={COOKIE_POlICY_EN} element={<CookiePolicyEn/>}/>
                        <Route path={COOKIE_POlICY_IT} element={<CookiePolicyIt/>}/>

                        <Route path={PRIVACY_POLICY} element={<PrivacyPolicy/>}/>
                        <Route path={PRIVACY_POLICY_EL} element={<PrivacyPolicyEl/>}/>
                        <Route path={PRIVACY_POLICY_EN} element={<PrivacyPolicyEn/>}/>
                        <Route path={PRIVACY_POLICY_IT} element={<PrivacyPolicyIt/>}/>

                        <Route path={SUCCESS_DELETE_ACCOUNT} element={<SuccessDeleteAccount/>}/>

                        <Route path="*" element={<Navigate to={HOME}/>}/>

                    </Route>
                </Routes>
            </BrowserRouter>
        </ConfigProvider>
        </ApolloProvider>
    );
}

export default provider(
    StoreDI(),
    ApolloClientDI()
)(observer(App));
