import React from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './SuperSlider.css'

export interface ISlider {
    children?: React.ReactNode[]
}

export const SuperSlider = ({children}: ISlider) => {

    const settings = {
        customPaging: function (i: number) {
            return (
                <span>{i+1}</span>
            );
        },
        dotsClass: 'dots',
        dots: true,
        arrows: false,
        // centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        // infinite: true,
        // speed: 500,
        // slidesToShow: 1,
        // slidesToScroll: 1,
        // variableWidth: true,
        // adaptiveHeight: true,
    };

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Slider {...settings}>
            {children}
        </Slider>
    )

}