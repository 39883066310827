import React from 'react';
import {ISolid, Solid} from 'blocks/Solids/Solid';
import cn from 'classnames'
import _ from 'lodash';

import cls from './Fares.module.scss'

export interface IFare {
    title: string
    cards: ISolid[]
    className?: string
}

export const Fare = ({title, cards, className}:IFare) => (
    <div className={cn([cls.fare, className])}>
        <div className={cn(cls.title, 'col4')}>{title}</div>
        <div className={cls.cards}>
            {_.map(cards, (item, index) => (
                <Solid key={index} title={item.title} content={item.content} className={cn(cls.card, 'col4')}/>
            ))}
        </div>
    </div>
)