import React from 'react';
import cn from 'classnames'

import cls from './Cards.module.scss'

export interface ICard {
    title?: string
    content: string
    className?: string
}

export const Card = ({title, content, className}:ICard) => (
    <div className={cn([cls.card, className])}>
        {title && <div className={'bold'}>{title}</div>}
        <div>{content}</div>
    </div>
)