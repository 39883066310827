import {gql} from '@apollo/client';

export const PARTNER = gql`
    query partner($locale: I18NLocaleCode) {
        partner(locale: $locale) {
            data {
                attributes {
                    CoverSubtitle
                    CoverTitle
                    CoverButton
                    TextBlockOne
                    AdvantagesTitle
                    AdvantagesImage {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                    AdvantagesCards {
                        id
                        Title
                        Description
                    }
                    TextBlockTwo
                    TextBlockTwoButton
                    GetTitle
                    GetImage {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                    GetCards {
                        id
                        Title
                        Description
                    }
                    EarnTitle
                    EarnChess {
                        Image {
                            data {
                                attributes {
                                    url
                                }
                            }
                        }
                        Card {
                            Title
                            Description
                        }
                    }
                    FareTitle
                    FareCards {
                        Title
                        Description
                    }
                    TextBlockThree
                    TextBlockThreeButton
                    FormTitle
                    FormButton
                }
            }
            
        }
    }
`;